import {
    BaseTypeEnum,
    IBaseUpsell,
    IMedia,
    IMenu,
    IParseProductOptions,
    IProduct,
    IQSROrder,
    ISocialMenu,
    IUpsell,
    MenuItemStatusEnum,
    ProductTypeEnum,
    UpsellPlacementEnum,
} from '@/repositories/menu/types';
import { IRestaurant } from '@clubpay/customer-common-module/src/repository/vendor/type';
import { cdnDomain } from '@clubpay/customer-common-module/src/repository/axios/constants';
import { changeDomain } from '@clubpay/customer-common-module/src/utility/k_url';
import DateService from '@clubpay/customer-common-module/src/service/date';
import { pickBy } from 'lodash';
import { getCurrencyPrecision } from '@clubpay/customer-common-module/src/utility/k_currency';
import { kRoundNumber } from '@/services/utils/k_round';
import { OrderPriceRoundingModeEnum } from '@clubpay/customer-common-module/src/repository/vendor';

export const applyUpsell = (upsell: IUpsell | undefined, arr?: any[]) => {
    if (!upsell || !arr) {
        return;
    }

    const toAdd = upsell.ids.map<IBaseUpsell>((id) => ({
        id,
        _upsell: {
            id,
            placement: upsell.placement,
            variant: upsell.variant,
        },
        _lazy: true,
        _type: BaseTypeEnum.Upsell,
    }));
    if (upsell.placement === UpsellPlacementEnum.Top) {
        arr.unshift(...toAdd);
    } else if (upsell.placement === UpsellPlacementEnum.Middle) {
        const middleIndex = Math.ceil(arr.length / 2); // Find middle index with ceil strategy
        arr.splice(middleIndex, 0, ...toAdd);
    } else {
        arr.push(...toAdd);
    }
};

const parseMedia = (
    media: IMedia[] | undefined,
    meta:
        | {
              _media?: IMedia[];
          }
        | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    vendor?: IRestaurant,
): IMedia[] | undefined => {
    if (media?.length) {
        const getCDN = (m: IMedia): Partial<IMedia> => {
            if (!cdnDomain) {
                return {};
            }
            return {
                optimizedFile: changeDomain(m?.optimizedFile, cdnDomain),
                originalFile: changeDomain(m?.originalFile, cdnDomain),
                thumbnail: m?.thumbnail?.url
                    ? {
                          url: vendor?.orderConfig?.useHighQualityImage
                              ? changeDomain(m?.originalFile, cdnDomain) || ''
                              : changeDomain(m?.thumbnail?.url, cdnDomain) || '',
                      }
                    : undefined,
            };
        };
        return [
            ...(meta?._media || []),
            ...media.map((m) => ({
                ...m,
                ...getCDN(m),
            })),
        ];
    }
    if (meta?._media?.length) {
        return meta._media;
    }
    return undefined;
};

export const parseSocialMenu = (item: ISocialMenu): ISocialMenu => {
    const getCDN = (): Partial<ISocialMenu> => {
        if (!cdnDomain) {
            return {};
        }
        return {
            backgroundUrl: changeDomain(item?.backgroundUrl, cdnDomain),
            logoSmallUrl: changeDomain(item?.logoSmallUrl, cdnDomain),
            logoBigUrl: changeDomain(item?.logoSmallUrl, cdnDomain),
        };
    };
    return {
        ...item,
        ...getCDN(),
    };
};

export const parseMenu = (menu: IMenu, vendor?: IRestaurant): IMenu => {
    const outOfSchedule = !DateService.getInstance().isAvailable(menu.availabilities);
    const m = {
        ...menu,
        media: parseMedia(menu.media, menu?.meta, vendor),
        disabled: menu.status === MenuItemStatusEnum.DISABLED || outOfSchedule,
        outOfSchedule,
        categories: menu.categories.map((cat) => {
            const catOutOfSchedule = !DateService.getInstance().isAvailable(cat.availabilities);
            return {
                ...cat,
                outOfSchedule: catOutOfSchedule,
                disabled: cat.status === MenuItemStatusEnum.DISABLED || catOutOfSchedule,
            };
        }),
    };
    if (m.upsell) {
        applyUpsell(m.upsell, m.categories);
    }
    return m;
};

const parseProductOption = (option?: IProduct): Partial<IProduct> => {
    if (!option) {
        return {};
    }
    return pickBy(
        {
            price: option.price,
            min: option.min,
            max: option.max,
            multiMax: option.multiMax,
        },
        (o) => o !== undefined,
    );
};

const getSkipValidation = (vendor?: IRestaurant) => {
    return (vendor?.orderConfig?.modifierValidationIgnore || []).reduce<{ [key: string]: number }>((a, field) => {
        a[field] = 0;
        return a;
    }, {});
};

const parseProductItem = (product: IProduct, root: boolean, options?: IParseProductOptions): IProduct => {
    const hidePrice = options?.vendor?.orderConfig?.hideBundlePrice;
    const outOfSchedule = !DateService.getInstance().isAvailable(product.availabilities);
    const p: IProduct = {
        ...product,
        ...parseProductOption(product.option),
        media:
            product?.media || product?.meta?._media
                ? parseMedia(product?.media, product?.meta, options?.vendor)
                : undefined,
        calories: product?.meta?._calories || { min: 0, max: 0 },
        outOfSchedule,
        defaultQuantity: product?.meta?._defaultQty || 0,
        displayPrice:
            hidePrice && product.type === ProductTypeEnum.Bundle
                ? ''
                : String(product.meta?.displayPrice || product.price),
        modifierGroups: product.modifierGroups?.map((mg) => {
            const catOutOfSchedule = !DateService.getInstance().isAvailable(mg.availabilities);
            return {
                ...mg,
                outOfSchedule: catOutOfSchedule,
                disabled: mg.status === MenuItemStatusEnum.DISABLED || catOutOfSchedule,
                products: mg.products?.map((prod) => {
                    return parseProductItem(prod, false, options);
                }),
                ...getSkipValidation(options?.vendor),
            };
        }),
        products: product.products?.map((prod) => {
            return parseProductItem(prod, false, options);
        }),
        disabled:
            outOfSchedule ||
            product.snoozed ||
            product.status === MenuItemStatusEnum.DISABLED ||
            product.inventory === 0,
    };
    if (root && p.upsell) {
        if (!p.modifierGroups) {
            p.modifierGroups = [];
        }
        applyUpsell(p.upsell, p.modifierGroups);
        if (p.upsell.ids?.length) {
            p.hasAdditives = true;
        }
    }
    return p;
};

export const parseProduct = (product: IProduct, options?: IParseProductOptions): IProduct => {
    const prod = parseProductItem(product, true, options);
    return {
        ...prod,
        categoryId: options?.categoryId,
        hasAdditives:
            prod.hasAdditives ||
            prod.type === ProductTypeEnum.Bundle ||
            Boolean(
                (prod.modifierGroups && prod.modifierGroups?.length > 0) ||
                    (prod.products && prod.products?.length > 0),
            ),
    };
};

export const parseOrder = (order: IQSROrder, vendor?: IRestaurant): IQSROrder => {
    const cp = vendor ? getCurrencyPrecision(vendor.country?.currencyCode) : 0;
    return {
        ...order,
        createdAt: new Date(order.createdAt),
        ...(vendor
            ? {
                  subTotal: kRoundNumber(
                      order?.subTotal,
                      cp,
                      vendor.orderConfig?.priceRoundingMode || OrderPriceRoundingModeEnum.Round,
                  ),
                  posTotal: kRoundNumber(
                      order?.posTotal,
                      cp,
                      vendor.orderConfig?.priceRoundingMode || OrderPriceRoundingModeEnum.Round,
                  ),
                  total: kRoundNumber(
                      order?.total,
                      cp,
                      vendor.orderConfig?.priceRoundingMode || OrderPriceRoundingModeEnum.Round,
                  ),
              }
            : {}),
    };
};

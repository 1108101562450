import {
    ICategoryProduct,
    IGetProductSelector,
    IMenu,
    IMenuPayload,
    ISessionEditPayload,
    IMenuResponse,
    IOrderData,
    IOrderGetActiveCountResponse,
    IOrderHistoryQueryParams,
    IOrderHistoryResponse,
    IOrderOptions,
    IOrderResponse,
    IPaginationPayload,
    IProduct,
    IRequest,
    ISocialMenu,
    ISocialMenuResponse,
    IUserInfoResponse,
    IUserInfoSetRequest,
    IOrderTransformOptions,
    IQRValidationResponse,
    ICategory,
} from '@/repositories/menu/types';
import apiEndpoints from '@/repositories/menu/const';
import { IURLTopology } from '@clubpay/customer-common-module/src/hook/router';
import { clone, cloneDeep, debounce, orderBy, uniqBy } from 'lodash';
import DateService from '@clubpay/customer-common-module/src/service/date';
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from '@clubpay/customer-common-module/src/repository/axios';
import { IRestaurant } from '@clubpay/customer-common-module/src/repository/vendor/type';
import { SingleFlight } from '@/repositories/menu/utility/single_flight';
import { parseJSON } from '@clubpay/customer-common-module/src/utility/k_json';
import { AxiosResponse } from 'axios';
import { applyUpsell, parseMenu, parseOrder, parseProduct, parseSocialMenu } from '@/repositories/menu/transformer';

export default class MenuAPIManager {
    private static instance: MenuAPIManager;

    public static getInstance() {
        if (!this.instance) {
            this.instance = new MenuAPIManager();
        }

        return this.instance;
    }

    private dateService = DateService.getInstance();

    private requests: IRequest[] = [];

    private productMap: { [key: string]: IProduct } = {};

    private readonly isSim: boolean = false;

    // @ts-ignore
    private menuSingleFlight: SingleFlight<IMenu>;

    // @ts-ignore
    private socialMenuSingleFlight: SingleFlight<ISocialMenu>;

    // @ts-ignore
    private productSingleFlight: SingleFlight<IProduct>;

    // @ts-ignore
    private categorySingleFlight: SingleFlight<ICategoryProduct>;

    constructor() {
        if (typeof window === 'undefined') {
            return;
        }

        this.isSim = window.location.href.includes('sim=true');

        this.menuSingleFlight = new SingleFlight<IMenu>(
            { many: this.getMenuPr },
            {
                useRequestCache: true,
                useItemCache: true,
                simMode: this.isSim,
            },
        );

        this.socialMenuSingleFlight = new SingleFlight<ISocialMenu>(
            { many: this.getSocialMenuPr },
            {
                useRequestCache: true,
                useItemCache: false,
            },
        );

        this.productSingleFlight = new SingleFlight<IProduct>(
            { one: this.getProductPr },
            {
                useItemCache: true,
                simMode: this.isSim,
            },
        );

        this.categorySingleFlight = new SingleFlight<ICategoryProduct>(
            { many: this.getCategoryPr },
            {
                useRequestCache: true,
                useItemCache: true,
                simMode: this.isSim,
            },
        );

        if (this.isSim) {
            window?.parent?.postMessage('customer_app_loaded', '*');
            window?.addEventListener('message', (e) => {
                if (!e.origin.includes('vendor') && !e.origin.includes('localhost')) {
                    return;
                }
                const data: { type: string; data: IMenu } = parseJSON(e.data);
                if (data?.type === 'menu') {
                    this.menuSingleFlight.setMany([parseMenu(data.data)], true);
                    data.data.categories?.forEach((cat) => {
                        const products = cat.products?.map((o) => parseProduct(o, { categoryId: cat.id })) || [];
                        this.categorySingleFlight.setMany(products, cat.id);
                        this.productSingleFlight.setMany(products);
                    });
                }
            });
        }
    }

    private getMenuPr = async ({
        payload,
        pagination,
        vendor,
    }: {
        payload: IMenuPayload;
        pagination?: IPaginationPayload;
        vendor?: IRestaurant;
    }) => {
        const res = await axiosInstance.Get<IPaginationPayload, AxiosResponse<IMenuResponse>>(
            apiEndpoints.menu.replace(':table_topology', this.getUrl(payload)),
            {
                params: this.getPaginationParam(pagination),
            },
        );
        DateService.getInstance().setServerTime(res.data?.timestamp || 0);
        return {
            rows: orderBy(
                (res.data?.data || []).map((o: IMenu) => parseMenu(o, vendor)),
                ['disabled'],
                ['asc'],
            ),
        };
    };

    private getSocialMenuPr = async ({
        payload,
        pagination,
    }: {
        payload: IMenuPayload;
        pagination?: IPaginationPayload;
        vendor?: IRestaurant;
    }) => {
        const res = await axiosInstance.Get<IPaginationPayload, AxiosResponse<ISocialMenuResponse>>(
            apiEndpoints.socialMenu.replace(':table_topology', this.getUrl(payload)),
            {
                params: this.getPaginationParam(pagination),
            },
        );
        DateService.getInstance().setServerTime(res.data?.timestamp || 0);
        return {
            rows: res.data?.data?.map((m) => parseSocialMenu(m)),
        };
    };

    private getProductPr = async ({
        payload,
        id,
        menuId,
        vendor,
        category,
    }: {
        payload: IMenuPayload;
        id: string;
        menuId: string;
        vendor?: IRestaurant;
        category?: ICategory;
    }): Promise<IProduct> => {
        const res = await axiosInstance.Get<IPaginationPayload, any>(
            apiEndpoints.product
                .replace(':table_topology', this.getUrl(payload))
                .replace(':hashId', id)
                .replace(':menuId', menuId),
        );
        return parseProduct(res.data.data, { vendor, category });
    };

    private getCacheProduct = (ids: string[]): IProduct[] => {
        if (ids.length === 0) {
            return [];
        }

        return ids.reduce<IProduct[]>((a, id) => {
            a.push(this.productMap[id]);
            return a;
        }, []);
    };

    private getManyProductPr = async ({
        payload,
        hashIds,
    }: {
        payload: IMenuPayload;
        hashIds: IGetProductSelector[];
    }): Promise<IProduct[]> => {
        const { foundIds, notFoundIds } = hashIds.reduce<{ foundIds: string[]; notFoundIds: IGetProductSelector[] }>(
            (a, id) => {
                if (this.productMap.hasOwnProperty(id.productId)) {
                    a.foundIds.push(id.productId);
                } else {
                    a.notFoundIds.push(id);
                }
                return a;
            },
            { foundIds: [], notFoundIds: [] },
        );
        if (foundIds.length === hashIds.length) {
            return Promise.resolve(this.getCacheProduct(foundIds));
        }

        return this.getProductByIds(this.getUrl(payload), notFoundIds);
    };

    private getProductHandler = () => {
        const requests = cloneDeep(this.requests);
        this.requests = [];
        requests.forEach((request) => {
            axiosInstance
                .Post<any, any>(apiEndpoints.productMany.replace(':table_topology', request.url), {
                    productList: request.ids,
                })
                .then((res) => res.data?.data || [])
                .then((res: IProduct[]) => {
                    const productMap = res.reduce<{ [key: string]: IProduct }>((a, c) => {
                        a[c.id] = c;
                        return a;
                    }, {});
                    request.promises.forEach((promise) => {
                        promise.resolve(
                            promise.ids.reduce<IProduct[]>((a, c) => {
                                const p = productMap[c.productId];
                                if (p) {
                                    a.push(p);
                                }
                                return a;
                            }, []),
                        );
                    });
                })
                .catch((err) => {
                    request.promises.forEach((promise) => {
                        promise.reject(err);
                    });
                });
        });
    };

    private debounceFn = debounce(this.getProductHandler, 250);

    private getProductByIds(url: string, ids: IGetProductSelector[]): Promise<IProduct[]> {
        return new Promise((resolve, reject) => {
            const idx = this.requests.findIndex((o) => o.url === url);
            if (idx === -1) {
                this.requests.push({
                    promises: [
                        {
                            reject,
                            resolve,
                            ids,
                        },
                    ],
                    ids: clone(ids),
                    url,
                });
            } else {
                const req = this.requests[idx];
                req.ids = uniqBy([...req.ids, ...ids], (o) => `${o.productId}_${o.menuId}`);
                req.promises.push({
                    reject,
                    resolve,
                    ids,
                });
            }
            this.debounceFn();
        });
    }

    private getCategoryFunc = async ({
        payload,
        hashId,
        menuId,
        pagination,
        vendor,
        category,
    }: {
        payload: IMenuPayload;
        hashId: string;
        menuId: string;
        pagination?: IPaginationPayload;
        vendor?: IRestaurant;
        category?: ICategory;
    }) => {
        const res = await axiosInstance.Get<IPaginationPayload, any>(
            apiEndpoints.category
                .replace(':table_topology', this.getUrl(payload))
                .replace(':hashId', hashId)
                .replace(':menuId', menuId),
            {
                params: this.getPaginationParam(pagination),
            },
        );
        DateService.getInstance().setServerTime(res.data?.timestamp);
        const rows = (res.data?.data?.products || []).map((o: ICategoryProduct) =>
            parseProduct(o, { vendor, categoryId: hashId, category }),
        );
        this.productSingleFlight.setMany(rows.filter((o: ICategoryProduct) => !o.more));
        if (category?.upsell) {
            applyUpsell(category.upsell, rows);
        }
        return {
            rows,
            hasMorePage: res.data?.data?.hasMorePage || false,
            scrollId: res.data?.data?.scrollId || '',
        };
    };

    private getCategoryPr = ({
        payload,
        hashId,
        menuId,
        pagination,
        vendor,
        category,
    }: {
        payload: IMenuPayload;
        hashId?: string;
        menuId?: string;
        pagination?: IPaginationPayload;
        vendor?: IRestaurant;
        category?: ICategory;
    }) => {
        if (!hashId || !menuId) {
            return Promise.reject(new Error('not implemented'));
        }

        return this.getCategoryFunc({
            payload,
            hashId,
            menuId,
            pagination,
            vendor,
            category,
        });
    };

    public getMenu = (payload: IMenuPayload, pagination?: IPaginationPayload, vendor?: IRestaurant) => {
        return this.menuSingleFlight.getMany({
            payload,
            pagination,
            vendor,
        });
    };

    public getSocialMenu = (payload: IMenuPayload, pagination?: IPaginationPayload, vendor?: IRestaurant) => {
        return this.socialMenuSingleFlight.getMany({
            payload,
            pagination,
            vendor,
        });
    };

    public getCategory = (
        payload: IMenuPayload,
        hashId: string,
        menuId: string,
        {
            pagination,
            vendor,
            category,
        }: {
            pagination?: IPaginationPayload;
            vendor?: IRestaurant;
            category?: ICategory;
        },
    ) => {
        return this.categorySingleFlight.getMany({
            payload,
            hashId,
            menuId,
            pagination,
            vendor,
            category,
        });
    };

    public getProduct = (
        payload: IMenuPayload,
        hashId: string,
        menuId: string,
        {
            vendor,
            category,
        }: {
            vendor?: IRestaurant;
            category?: ICategory;
        },
    ) => {
        return this.productSingleFlight.getOne(hashId, payload, menuId, vendor, category);
    };

    public getManyProduct = async (
        payload: IMenuPayload,
        hashIds: {
            productId: string;
            menuId: string;
        }[],
        vendor?: IRestaurant,
    ): Promise<{
        [key: string]: IProduct;
    }> => {
        const items = this.productSingleFlight.getManyCache(hashIds.map((o) => o.productId));
        const notFoundIds = items.reduce<
            {
                productId: string;
                menuId: string;
            }[]
        >((a, b, index) => {
            if (!b) {
                const item = hashIds[index];
                a.push({
                    ...item,
                    menuId: item.menuId || 'upsell',
                });
            }
            return a;
        }, []);
        if (notFoundIds.length > 0) {
            const remoteProduct = await this.getManyProductPr({
                payload,
                hashIds: notFoundIds,
            });
            remoteProduct.forEach((product: IProduct) => {
                if (!product) {
                    return;
                }

                const parsedProduct = parseProduct(product, { vendor });
                this.productSingleFlight.setOne(parsedProduct);
                items.push(parsedProduct);
            });
        }
        return items.reduce<{
            [key: string]: IProduct;
        }>((a, b) => {
            if (b) {
                a[b.id] = b;
            }
            return a;
        }, {});
    };

    public postOrder = async (
        payload: IMenuPayload,
        orderData: IOrderData,
        recaptcha: string,
        options?: IOrderOptions,
    ) => {
        return axiosInstance
            .Post<IOrderResponse>(
                apiEndpoints.orderCreate.replace(':table_topology', this.getUrl(payload)),
                this.transformOrderPayload(orderData, {
                    multiOrder: options?.multiOrder,
                    recaptcha,
                    ignoreBatch: options?.unifyTimestamp,
                }) as any,
                {
                    params: {
                        ...(options?.qrpc ? { qrpc: options.qrpc } : {}),
                        ...(options?.vendorToken ? { vendorToken: options.vendorToken } : {}),
                    },
                },
            )
            .then((res) => {
                return parseOrder(res.data?.data, options?.vendor);
            });
    };

    public editOrder = async (payload: IMenuPayload, orderData: IOrderData, options?: IOrderOptions) => {
        return axiosInstance
            .Put<IOrderResponse>(
                apiEndpoints.orderEdit.replace(':table_topology', this.getUrl(payload)),
                this.transformOrderPayload(orderData, {
                    multiOrder: options?.multiOrder,
                    ignoreBatch: options?.unifyTimestamp,
                }) as any,
                {
                    params: {
                        ...(options?.qrpc ? { qrpc: options.qrpc } : {}),
                        ...(options?.vendorToken ? { vendorToken: options.vendorToken } : {}),
                    },
                },
            )
            .then((res) => {
                return parseOrder(res.data?.data, options?.vendor);
            });
    };

    public editSession = async (urlTp: IURLTopology, payload: ISessionEditPayload) => {
        return axiosInstance
            .Put<ISessionEditPayload, any>(
                apiEndpoints.sessionEdit.replace(':table_topology', this.getUrl(urlTp)),
                payload,
            )
            .then((res) => {
                return res;
            });
    };

    public getOrder = (payload: IMenuPayload, refId: string, options?: IOrderOptions) => {
        return axiosInstance
            .Get<IOrderResponse>(
                apiEndpoints.orderGet.replace(':table_topology', this.getUrl(payload)).replace(':refId', refId),
                {
                    params: { ...(options?.vendorToken ? { vendorToken: options.vendorToken } : {}) },
                },
            )
            .then((res) => {
                return parseOrder(res.data?.data, options?.vendor);
            });
    };

    public getOrderHistory = (payload: IMenuPayload, queryParam: IOrderHistoryQueryParams, vendor?: IRestaurant) => {
        return axiosInstance
            .Get<IOrderHistoryResponse>(apiEndpoints.orderGetHistory.replace(':table_topology', this.getUrl(payload)), {
                params: queryParam,
            })
            .then((res) => {
                return (
                    res.data?.data?.list?.map((item) => {
                        return parseOrder(item, vendor);
                    }) || []
                );
            });
    };

    public getOrderActiveCount = (payload: IMenuPayload) => {
        return axiosInstance
            .Get<IOrderGetActiveCountResponse>(
                apiEndpoints.orderGetActiveCount.replace(':table_topology', this.getUrl(payload)),
            )
            .then((res) => {
                return res.data?.data?.count || 0;
            });
    };

    public getUserInfo = (payload: IMenuPayload) => {
        return axiosInstance
            .Get<IUserInfoResponse>(apiEndpoints.userInfoGet.replace(':table_topology', this.getUrl(payload)))
            .then((res) => {
                return res.data?.userInfo;
            });
    };

    public setUserInfo = (payload: IMenuPayload, userData: IUserInfoSetRequest) => {
        return axiosInstance
            .Post<IUserInfoResponse>(apiEndpoints.userInfoSet.replace(':table_topology', this.getUrl(payload)), {
                ...userData,
            })
            .then((res) => {
                return res.data?.userInfo;
            });
    };

    public getPager = (payload: IMenuPayload, recaptcha: string) => {
        return axiosInstance
            .Post<any>(apiEndpoints.pagerGet.replace(':table_topology', this.getUrl(payload)), { recaptcha })
            .then((res) => {
                return res.data.data;
            });
    };

    private getUrl(payload: IURLTopology) {
        return `${payload.cc}/${payload.slug}/${payload.id}/${payload.f1 || '_'}/${payload.f2 || '_'}/${
            payload.hash || '_'
        }`;
    }

    private getPaginationParam(payload?: IPaginationPayload) {
        if (payload?.scrollId) {
            return {
                limit: payload?.limit || 10,
                scrollId: payload?.scrollId || 0,
            };
        }
        return {
            limit: payload?.limit || 10,
            page: payload?.page || 0,
        };
    }

    private transformOrderPayload = (order: IOrderData, options: IOrderTransformOptions): IOrderData => {
        const unix = this.dateService.getUnix();
        let firstBatchItem = true;
        return {
            ...order,
            orderData: {
                ...order.orderData,
                version: 'v2',
                ...(options.multiOrder
                    ? {
                          items: order.orderData.items.map((item) => {
                              if (!item.uid) {
                                  const additionalPayload = firstBatchItem
                                      ? { batchNote: order.customerComment || '' }
                                      : {};
                                  firstBatchItem = false;
                                  return {
                                      ...item,
                                      uid: uuidv4(),
                                      timestamp: unix,
                                      ...additionalPayload,
                                  };
                              }
                              if (options.ignoreBatch) {
                                  return {
                                      ...item,
                                      timestamp: unix,
                                  };
                              }
                              return item;
                          }),
                      }
                    : {}),
            },
            customerComment: options.multiOrder && !options.ignoreBatch ? '' : order.customerComment,
            ...(options.recaptcha ? { recaptcha: options.recaptcha } : {}),
        };
    };

    public validateDynamicQr = (payload: IMenuPayload, passcode: string) => {
        return axiosInstance
            .Get<IQRValidationResponse>(
                apiEndpoints.dynamicQrValidate.replace(':table_topology', this.getUrl(payload)),
                {
                    params: {
                        qrpc: passcode,
                    },
                },
            )
            .then((res) => {
                return res.data;
            });
    };
}
